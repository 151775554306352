@import "src/styles/_variables.scss";

.viewHiring {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.subCategory {
  font-weight: bold;
  color: $primary-color;
}
.textContainer {
  font-size: 14px;
  color: $primary-color;
  margin-top: 6px;
}
.value {
  font-weight: 700;
}
.textDescription {
  margin-top: 12px;
  margin-bottom: 20px;
}
.content {
  margin-top: 16px;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  .banner {
    min-width: 200px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .cssBanner {
    height: 100px;
    width: 200px;
    border-radius: 10px;
    object-fit: cover;
  }
}
