@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  overflow: hidden;
}

.mr-10 {
  margin-right: 10px;
}

.min-height-50 {
  min-height: 50px;
}

.detail-row {
  display: flex;
  align-items: center;
  .row-label {
    min-width: 200px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #3b3b3b;
    &::after {
      content: ": ";
    }
  }
  .row-value {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3b3b3b;
  }
}

.detail-row + .detail-row {
  margin-top: 20px;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-space-between{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.ellipsis-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.mw-150 {
  max-width: 150px;
}

.flex-align-item-center {
  display: flex;
  align-items: center;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-bold-500 {
  font-weight: 500;
}

.text-bold-700 {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}
