.container {
  :global {
    .ant-pagination {
      margin-top: 10px;
    }
  }
}
.avatarTable {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
