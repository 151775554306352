.container {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
}

.search {
  & > *:not(:last-child) {
    margin-right: 16px;
  }
}
