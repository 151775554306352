@import "../../styles/variables";
.pageWrapper {
  min-height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
  display: flex;
}
.mainWrapper {
  height: 100%;
  flex: 1;
  transition: all 0.3s ease 0s;
}
.pageContent {
  flex: 1;
  background: #f9f9f9;
  height: 100%;
  overflow: auto;

  height: calc(100vh - $header-height);
  padding: 24px;
  max-width: calc(100vw - 250px);
}
