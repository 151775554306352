.container {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
  font-size: 12px;
}
