@import "../../styles/variables";

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: $header-height;
  background-color: #fff;
  border-bottom: 1px #ddd solid;
  padding: 0 10px;
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .languages {
      min-width: 200px;
    }
    .menuWrapper {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menuItem {
        padding: 20px;
        position: relative;
        cursor: pointer;
        .badge {
          position: absolute;
          top: 8px;
          right: 4px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background-origin: padding-box;
          color: #fff;
          min-width: 14px;
          height: 14px;
          background-color: #eb5757;
          border-radius: 50%;
        }
        &:last-child img {
          border-radius: 50%;
          border: 1px solid #c4c4c4;
        }
      }
    }
  }
}
