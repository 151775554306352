@import "src/styles/_variables.scss";

.sideNav {
  background: #fff;
  border-right: 1px solid #f0f0f0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  min-height: 100vh;
  min-width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  :global {
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
    .anticon {
      width: 12px;
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title,
    .ant-menu-item-selected {
      background-color: $primary-color !important;
      color: $white-color;
    }

    .ant-menu-item-icon {
      color: black !important;
    }
  }
  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
  }
  .menu {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.sideNavCollapsed {
  .logo {
    font-size: 12px;
  }
}
