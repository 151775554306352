@import "./variables";

.input-search-custom {
  max-width: 300px;
}

.table-custom {
  &--select-row {
    td {
      cursor: pointer;
    }
  }
}

.select-custom {
  min-width: 130px;
}

.textarea-custom {
  height: 135px;
  textarea {
    resize: none;
  }
}

.ant-form-item-label {
  padding: 0 0 5px !important;
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
}

.button {
  border-radius: 50px;
  height: 45px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  min-width: 150px;
}

.disabledButton {
  border-radius: 50px;
  height: 45px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  min-width: 150px;
}

.linear {
  background: linear-gradient(270deg, #e3aece 0%, #bba1cb 48.44%, #81cde4 100%);
}

.input {
  border: 0.5px solid #c4c4c4;
  padding: 10px 10px;
  border-radius: 10px;
  height: 44px;
  .ant-input-prefix {
    margin-right: 10px;
  }
}

.date-picker {
  border: 0.5px solid #c4c4c4;
  padding: 10px 10px;
  border-radius: 10px;
}

.table {
  .ant-table-thead > tr > th {
    padding: 14px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 8px;
    color: $text-table-color;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    display: none;
  }
  .ant-pagination-item-active {
    background-color: $primary-color !important;
  }
  .ant-pagination-item-active a {
    color: $white-color;
    font-weight: bold;
  }
  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
    background-color: #eeeeee;
    border-radius: 4px;
  }
}

.content-page {
  margin-top: 16px;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0;
}

.modalCustom {
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      background: #87d6ea;
      padding: 12px 20px;
      margin-bottom: 0;
      .ant-modal-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      svg {
        cursor: pointer;
      }
    }
    .ant-modal-body {
      padding: 30px 20px;
    }
  }
}

.select {
  .ant-select-selector {
    min-height: 44px !important;
    display: flex;
    align-items: center;
    border-radius: 10px !important;
  }
  &.ant-select-disabled .ant-select-selector {
    color: #3b3b3b !important;
  }
  &.ant-select-disabled .ant-select-selection-item {
    color: #3b3b3b !important;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
}

.tab-container {
  background: #fff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    background: #f4fcff;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 10px 10px 0px 0px;
    height: 55px;
  }
  .ant-tabs-tab {
    margin: 0 !important;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #909090;
  }
  .ant-tabs-tabpane {
    padding: 0;
  }
}

.checkbox {
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    &::after {
      inset-inline-start: 23.5%;
    }
  }
}

.label {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 5px;
}

.ant-input-disabled {
  color: #3b3b3b !important;
}

.spin {
  text-align: center;
}

.text-primary {
  color: $primary-color;
}

.rate {
  line-height: 20px;
}

a {
  color: $primary-color;
}

.user-id {
  color: #909090;
  margin-left: 4px;
  font-weight: normal !important;
}
