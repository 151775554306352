$header-height: 60px;
$side-nav-width: 220px;

$primary-color: #87d6ea;
$white-color: #fff;
$gray-color: #eeeeee;
$total-title-color: #a29490;
$dark-red-color: #a61f17;
$header-table-color: rgba($primary-color, 0.25);
$category-color: rgba($primary-color, 0.15);
$text-table-color: #4f4f4f;
$text-primary-color: #3b3b3b;
$text-second-color: #888888;
