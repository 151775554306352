.container {
  .title {
    font-size: 30px;
    font-weight: 700;
  }
  .filter {
    margin-top: 10px;
  }
  .table {
    margin-top: 20px;
  }
  .tabs {
    :global {
      .ant-tabs-tabpane {
        padding: 0;
      }
    }
  }
}
